.comicSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.comicFrame {
  width: 300px;
  height: 200px;
  border: 4px solid black;
}
