.zone {
  color: #fff;
  font-size: 2em;
  border-radius: 4px;
  border: 1px solid #bbb;
  transition: all 0.3s linear;
}

/* .zone:hover {
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
  -o-box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
} */

footer {
  background-color: #de6600;
  color: #003f5a;
}

.footer-content {
  display: flex;
  list-style: none;
  height: 1.5;
  margin: 0;
  width: 100%;
  flex-shrink: 0;
}

.footer-right {
  margin-right: auto;
  color: #003f5a;
  cursor: auto;
}

.footer-link {
  text-decoration: none;
  font-size: 0.7em;
  margin: 0 auto;
  padding: 10px;
}

/* ----------- iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  footer {
    max-width: 100vw;
  }

  .footer-link {
    font-size: 0.5em;
    letter-spacing: 1.5px;
    line-height: 1.5;
    margin-left: 5px;
    margin-right: 10px;
    text-align: center;
  }
}
