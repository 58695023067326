.zone {
  color: #fff;
  font-size: 2em;
  border-radius: 4px;
  border: 1px solid #bbb;
  /* transition: all 0.3s linear; */
}

/* .zone:hover {
  -webkit-box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
  -moz-box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
  -o-box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
  box-shadow: rgba(0, 0, 0, 0.8) 0px 5px 15px,
    inset rgba(0, 0, 0, 0.15) 0px -10px 20px;
} */

.sticky {
  position: sticky;
  top: 0;
  width: 100%;
}
.siteTitle {
  font-size: 25px;
}
.main-nav {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 5px;
  background-color: #de6600;
  z-index: 8;
}

.right {
  margin-right: auto;
}

.link {
  color: #003f5a;
  text-decoration: none;
  font-size: 0.7em;
  padding: 15px;
  letter-spacing: 2px;
}

/* ----------- Retina Screens ----------- */
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .main-nav {
    max-width: 100vw;
  }
  .link {
    padding: 15px;
    padding-right: 15px;
    font-size: 0.5em;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .main-nav {
    max-width: 100vw;
  }
  .link {
    padding-right: 10px;
    font-size: 0.6em;
  }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .main-nav {
    max-width: 110vw;
  }
  .link {
    font-size: 0.5em;
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .main-nav {
    max-width: 100vw;
  }
  .link {
    font-size: 0.5em;
  }
}

/* ----------- iPhone X ----------- */

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .main-nav {
    max-width: 100vw;
  }
  .link {
    padding: 12px;
    font-size: 0.5em;
  }
}
